
@keyframes slidein {
    0% {
        top: -400px;
        opacity: 0;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes slideout {
    0% {
        top: 0;
        opacity: 1;
    }
    100% {
        top: +400px;
        opacity: 0;
    }
}

#project0{
    opacity: 1;
}

#project1{
    display: none;
}

#project2{
    display: none;
}

#project3{
    display: none;
}

#project4{
    display: none;
}

#project5{
    display: none;
}

#project6{
    display: none;
}

.projects-container{
    overflow: auto;
}

.pc-right{
    overflow: auto;
    padding: 1rem;
    margin-right: 1.5rem;
}

.pc-left{
    flex-shrink: 10;
    margin: auto;
    max-width: 20rem;
}

.project-tile{
    background-color: rgba(29, 25, 13, 0.13);
    box-shadow:  10px 10px 10px 1px rgba(14, 1, 1, 0.68);
    border: 2px solid rgba(14, 1, 1, 0.22);
    border-radius: 20px;
    opacity: 0.8;
    padding: 2rem;
    min-height: 20rem;
    min-width: 15rem;
    max-width: 90rem;
    margin: auto;
    text-align: center;
    overflow: auto;
}

.plist{
}

.item {
    opacity: 0.75;
    text-align: center;
    height: 5rem;
    min-width: 17rem;
    max-width: 18rem;
    padding: 0.3rem;
    box-shadow:  5px 5px 30px 2px rgba(14, 1, 1, 0.68);
    border-radius:20px;
    margin-bottom: 0.5rem;
}

.item:hover { position: relative;
    box-shadow:  10px 10px 30px 2px rgba(52, 9, 1, 0.68);
    top: -0.2rem;
    left: -0.2rem;
    border-radius:30px;
    opacity: 1;
    cursor: pointer;
}

@media only screen and (min-width: 769px) {

}


@media (max-width: 768px) {
    .item {
        max-height: 2rem;
        min-height: 2rem;
        min-width: 9rem;
        max-width: 9rem;
        padding: 0.2rem;
        text-align: center;
    }

    .item-desc{
        display: none;
    }

    .project-tile{
        padding: 0.2rem;
        max-width: 85%;
        min-height: 1rem;
        opacity: 1;
    }

    .pc-right{
        margin: auto;
        padding: 0;
    }

    .plist{
        padding: 0;
        margin-top: 1rem;
    }

    #project0{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project1{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project2{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project3{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project4{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project5{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }

    #project6{
        display: flex;
        opacity: 1;
        margin-bottom: 1rem;
    }
}